<template>
        
            <button :type="type" class="btn ButtonHover px-3 py-2" :class="!(custom)?'quizell-border':''" ref="btn" @click="$emit('click')" v-bind="$attrs" >
              <span v-if="!isLoading"><slot>
                Button
              </slot></span>
              <div
                v-else
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </button>
       
</template>
<script>


export default ({
  props:{
    type:String,
    isLoading:Boolean,
    custom:Boolean
  },
  watch:{
    isLoading(newVal){
    
      this.$refs.btn.disabled=newVal
      
    }
  }
})
</script>
<style scoped>
button{
  border-radius: 6px;
    min-width: 119px;
    font-size: 14px;
    transition: 0.3 ease all;
}
.quizell-border{
    background: #fff;
    color:var(--primary-color);
}
.quizell-border:not(:disabled):not(.disabled):active {
     box-shadow: inset 0 0 10.2px 0.5rem rgba(80, 58, 60, 0.25)!important;
}
.quizell-border:active{
    background-color: #1f0637;
  color: #ffffff;
  border-color:#1f0637;
}

.black-border-button{
  border: 1px solid var(--text-color);
  color: var(--black);
}

.black-border-button:hover{
  border: 1px solid var(--text-color);
  background-color: var(--text-color);
  color: var(--white)
}
.secondary-color-button{
  color:var(--secondary-color); border:1px solid var(--secondary-color); background:var(--white)
}
.secondary-color-button:hover{
  color:var(--white); border:1px solid var(--secondary-color); background:var(--secondary-color)
}
</style>
