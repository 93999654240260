<template>
  <div style="font-size: 14px; z-index: 11;">
    <div v-if="isCalender"
      class="custom-filter-select"
     @click="toggleDropdown" 
      ref="customSelect" >
    
     <date-range-picker
              ref="picker"
              opens="center"
              :ranges="customRanges"
              control-container-class="btn btn-white"
              :append-to-body="true"
              :locale-data="{ firstDay: 1, format: 'mm-dd-yyyy' }"
              v-model="range"
              @update="updateValues"
              :maxDate="today">
              <template #input="picker" >
                
                <div class="d-flex justify-content-between date-formate align-items-center"  >
       
      <span style="text-wrap: nowrap;" v-if="picker.startDate"
                    >{{ customDateFormat(picker.startDate) }} -
                    {{ customDateFormat(picker.endDate) }}</span
                  >
                  <span v-else class="">Select Date</span>
    <span style="line-height: 1;" >
        <b-icon
              class=""
              :icon="isDropdownOpen ? 'chevron-up' : 'chevron-down'" />
    </span>
      </div>
              </template>
              <template #ranges="ranges" >
                <div class="ranges custom-ranges">
                  <ul>
                    <li
                      v-for="(range, name) in ranges.ranges"
                      :key="name"
                      @click="ranges.clickRange(range)">
                      <b>{{ name }}</b>
                    </li>
                  </ul>
                </div>
              </template>
              <template #footer="data">
                <div class="ranges custom-ranges">
                  <div class="d-flex justify-content-end align-items-center">
                    <small class="text-muted px-2">{{
                      data.rangeText.split("-").length == 2
                        ? "Select Date"
                        : data.rangeText
                    }}</small>
                    <b-button
                      variant="link p-0"
                      style="font-size: 13px; color: #4d1b7e"
                      @click="data.clickCancel"
                      >Cancel</b-button
                    >
                    <Button @click="data.clickApply" style="transform: scale(0.7);background-color:var(--primary-color)!important;"
                      >Apply</Button
                    >
                  </div>
                </div>
              </template>
            </date-range-picker>
    </div>
    <!--  -->
    <div 
   v-else
      class="custom-filter-select"
     @click="toggleDropdown" 
      ref="customSelect" >
      <div class="d-flex justify-content-between align-items-center"  >
        <span class="text-capitaliz" style="text-wrap: nowrap; text-transform: capitalize; overflow: hidden; ">{{
          selectedOptions.length > 0 ||
          (type == "radio" && formatSelectedOptions)
            ? filterFor + ": " + formatSelectedOptions
            : `${filterFor} `
        }}</span>
<span class=" "  @click="clearSelection" v-if="(selectedOptions.length > 0 || (type === 'radio' && formatSelectedOptions))&&isCrossBtn">
    
    <b-icon
      class="cross-btn"
      
     
      icon="x" />
    </span>
    <span  v-else>
        <b-icon
              class=""
              :icon="isDropdownOpen ? 'chevron-up' : 'chevron-down'" />
    </span>
      </div>
      <transition name="slide">
        <div v-show="isDropdownOpen" class="options" @click.stop="">
          <div class="py-4" v-if="loadingFilter">
             <div
                        class="d-flex align-items-center justify-content-center flex-column"
                      >
                        <b-spinner
                          small
                          label="Small Spinner"
                          style="color: #4d1b7e"></b-spinner
                        ><span class="ml-2">Loading {{ filterFor  }}...</span>
                      </div>
          </div>
          <div class="" v-else-if="getOptions.length > 0">
            <div class="v-16-input-control mx-2 my-1">
               <input type="text" v-model="filterText" :placeholder="'Search '+filterFor" name="" id="">
             </div>
             <template v-if="filteredOptions.length > 0">
               <label
                 class="m-0 py-1"
                 v-for="(option, index) in filteredOptions"
                 :key="option[label] + index">
                 <b-form-checkbox
                   v-if="type == 'checkbox'"
                   v-model="selectedOptions"
                   :value="option"
                   class="checkbox d-flex align-items-center cursor-pointer">
                   {{ option[label] }}</b-form-checkbox
                 >
                 <b-form-radio
                   v-else
                   v-model="selectedOptions"
                   :value="option"
                   class="checkbox d-flex align-items-center cursor-pointer">
                   {{ option[label] }}</b-form-radio
                 >
               </label>
             </template>
             <div class="px-2 py-4" style=" text-transform: capitalize;" v-else>{{ filterFor }} not found.</div>
          </div>
          <div class="px-2 py-4" style=" text-transform: capitalize;" v-else>{{ filterFor }} not available.</div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import axios from "axios";
export default {
  components:{
    DateRangePicker,
    Button: () =>
      import(
        /* webpackChunkName: "Button" */ "./Button.vue"
      ),
   
  },
  props: {
    isCalender:{
      type:Boolean,
      default:false
    },
    isCrossBtn:{
      type:Boolean,
      default:true
    },
    type: {
      type: String,
      default: "checkbox",
    },
    label: {
      type: String,
      default: "title",
    },
    filterFor: {
      type: String,
      default: "quizzes",
    },
    isDynamic: {
      type: Boolean,
      default: true,
    },
    staticOptions: {
      type: Array,
      default: () => [],
    },
    selectedObject:{
      type:Object,
      default:()=>{}
    },
    selectedArray:{
      type:Array,
      default:()=>[]
    }
  },
  watch: {
    selectedArray(newValue){
      if(newValue.length>0)
      this.selectedOptions = newValue?this.selectedArray:[]
    },
    selectedObject(newValue,old){
     
      if(old == undefined)
      {

        this.selectedOptions = newValue?newValue:{}
      }
    },
    selectedOptions: {
      handler(newValue) {
        this.$emit("update", newValue);
      },
      deep: true,
    },
  },
  computed: {
     customRanges() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const yesterday = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 1
      );
      const thisMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);
      const thisMonthEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0);

      const lastMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0);

      const thisWeekStart = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - now.getDay()
      );
      const thisWeekEnd = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - now.getDay() + 6
      );

      // Uncomment and use these if you need 'Last Week' range
      // const lastWeekStart = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 7);
      // const lastWeekEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 1);

      const last15DaysStart = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 14
      );
      const last15DaysEnd = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      );
      return {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "This Month": [thisMonthStart, thisMonthEnd],
        "Last Month": [lastMonthStart, lastMonthEnd],
        "This Week": [thisWeekStart, thisWeekEnd],
        // 'Last Week': [lastWeekStart, lastWeekEnd],
        "Last 15 Days": [last15DaysStart, last15DaysEnd],
        Clear: [null, null], // 'Clear' option to clear the selection
      };
    },
    getOptions() {
    
     return this.isDynamic? this.options: this.staticOptions;
    },
    filteredOptions(){
    return  this.getOptions.filter((option)=>option[this.label].toLowerCase().includes(this.filterText.toLowerCase()))
    },
    formatSelectedOptions() {
      if (this.type == "checkbox")
        return this.selectedOptions
          .map((option) => option[this.label])
          .join(", ");
      else return this.selectedOptions[this.label];
    },
  },
  data() {
    return {
      isFirstTime:false,
        range: {
        startDate: null,
        endDate: null,
        
      },
      filterText:'',
      today: new Date(),
      isDropdownOpen: false,
      options: [],
      selectedOptions:this.selectedObject?this.selectedObject: [],
      transitionClass: "slide", // Default transition class
      loadingFilter: false,
    };
  },

  methods: {
       updateValues(dates) {
        this.$emit('updateValues',dates)
    },
    customDateFormat(date, isUpdateFormate = false) {
      const inputDate = new Date(date);

      const day = String(inputDate.getDate()).padStart(2, "0");
      const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = inputDate.getFullYear();

      const formattedDate = isUpdateFormate
        ? `${year}-${month}-${day}`
        : `${day}-${month}-${year}`;
      return formattedDate;
    },
    async filterHandle() {
      this.loadingFilter = true;

      const response = await this.$store.dispatch("fetchData", {
        axiosMethod: "get",
        resource: "products",
        method: "filter",
      });
      if (response && response.status) {
        if (this.filterFor == "tags") this.options = response.data.tags;
        else this.options = response.data.collection;
      }
      this.loadingFilter = true;

    },
    async getQuizzes() {
      
      this.loadingFilter = true;
      await axios
        .post("/filter", { page_name: "lead" })
        .then((response) => {
         
          this.options = response.data.data.quizzes;
        })
        .catch((e) => console.log(e));

      this.loadingFilter = false;
    },
   toggleDropdown() {
 
  this.isDropdownOpen = !this.isDropdownOpen;

  if (this.isDropdownOpen) {
    this.$nextTick(() => {
      document.addEventListener("click", this.closeDropdownOnClickOutside);
    });
  } else {
    document.removeEventListener("click", this.closeDropdownOnClickOutside);
  }
},
    closeDropdownOnClickOutside(event) {
      // Check if the clicked element is outside the custom select dropdown
      if (!this.$refs.customSelect.contains(event.target)) {
        this.isDropdownOpen = false;
        document.removeEventListener("click", this.closeDropdownOnClickOutside);
      }
    },
    clearSelection() {
      this.selectedOptions = [];
      this.isDropdownOpen = false; // Close the dropdown when clearing selection
    },
    clear(){
      this.clearSelection()
        //  this.selectedOptions = [];
    },
  },
  mounted() {
    if (this.isDynamic) {
      if (this.filterFor === "quizzes") 
      {this.getQuizzes();}
      else 
      {this.filterHandle();}
    }
    
  },
};
</script>
<style>
.vue-daterange-picker{
  width:100%;
}
.vue-daterange-picker .btn{
  padding:0px;
  width:100%;
  font-size:14px;
  text-wrap: nowrap;
}
</style>
<style scoped>
.custom-filter-select {
  position: relative;
  cursor: pointer;

    padding: 10px 12px;
   
    border-radius: 6px;
    border: 1px solid var(--border-color);
    background: #F6F6F8;
    color: var(--grey-button);
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  /* padding: 3px 10px; */
  max-width: 250px;
  min-width: 150px;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color) !important;
  top: 2px;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::after,.checkbox >>> .custom-control-input:checked ~ .custom-control-label::after {

  top: 2px;
}
.checkbox>>>.custom-control-label{

  line-height: 2;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
  top: 2px;
}
.options {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  max-height: 200px;
  overflow-y: auto;
  z-index: 2;
  border-radius: 12px;
  width: 200px;
}

label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  padding: 11px 12px;
}

.slide-down-enter-active,
.slide-up-leave-active,
.slide-down-enter-active,
.slide-up-leave-active {
  transition: all 0.3s;
}

.slide-down-enter,
.slide-up-leave-to,
.slide-up-enter,
.slide-down-leave-to {
  opacity: 0;
  transform: translateY(-20%);
}
.cross-btn:hover {
  color: #4d1b7e;
}

.custom-filter-select .vue-daterange-picker{
  display: block
}
.date-formate {
  width:inherit;
  color: var(--grey-button);
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

</style>
<style>
</style>